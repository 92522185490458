import Head from "next/head"
import ChatIcon from "@material-ui/icons/ChatBubble"

export type AppHeadProps = {
  title?: string
  enableCrispChat?: boolean
}

export default function AppHead({
  title = "Pipeline Pro Tools",
  enableCrispChat = true,
}: AppHeadProps): JSX.Element {
  return <>
    <Head>
      <title>{title}</title>
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:wght@600;700&display=swap"
        rel="stylesheet"
      ></link>
      <link rel="icon" type="image/png" href="/favicon.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="/favicon.png" sizes="16x16" />
      <meta property="og:title" content={title}></meta>
      <meta
        property="og:description"
        content="Automate your lead pipline with Pipeline Pro Tools."
      ></meta>
      <meta property="og:image" content=""></meta>
      <meta property="og:url" content=""></meta>
      <meta name="twitter:card" content="summary_large_image"></meta>
    </Head>
    {enableCrispChat ? <SupportModal/> : null}
  </>
}

export function SupportModal(): JSX.Element {
  return <a 
    style={{
      width: "55px", 
      height: "55px",
      bottom: "10px",
      right: "10px"
    }}
    title="support"
    className="fixed z-50 rounded-full bg-blue-700 hover:bg-blue-600 shadow-lg flex items-center justify-center"
    href="https://pipelineprotools.com/support"
    target="_blank"
  >
    <div>
      <ChatIcon/>
    </div>
  </a>
}