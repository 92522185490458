export type LogoProps = {
  textColor?: string
  className?: string
}

export default function Logo(props: LogoProps): JSX.Element {
  const {textColor = "#eee", className} = props
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1077 281"
      aria-labelledby="logo-title"
      className={className}
      role="img"
    >
      <title id="logo-title">
        {"Pipeline Pro Tools Logo"}
      </title>
      <defs>
        <style>
          {`
            .cls-1 {
              fill: #cb3d44;
            }

            .cls-2 {
              fill: #0060ad;
              opacity: 0.71;
            }
            .ppt-text {
              stroke: #eee;
              fill: ${textColor};
            }
          `}
        </style>
      </defs>
      <g>
        <path
          className="ppt-text"
          d="M51.89,35.74c10.45,0,18.65,8.07,18.65,20.31s-8.2,20.66-18.65,20.66A17.91,17.91,0,0,1,38.07,70.3V94.93H29.86V36.39h8.21v5.83A17.31,17.31,0,0,1,51.89,35.74Zm-1.8,7.13c-6.12,0-12,4.82-12,13.32s5.9,13.39,12,13.39,12.1-5,12.1-13.53S56.29,42.87,50.09,42.87Z"
        />
        <path
          className="ppt-text"
          d="M78,25.88a5.22,5.22,0,1,1,5.25,5.25A5.19,5.19,0,0,1,78,25.88ZM79,36.39h8.21V76.06H79Z"
        />
        <path
          className="ppt-text"
          d="M120.44,35.74c10.44,0,18.65,8.07,18.65,20.31s-8.21,20.66-18.65,20.66a17.92,17.92,0,0,1-13.83-6.41V94.93H98.4V36.39h8.21v5.83A17.33,17.33,0,0,1,120.44,35.74Zm-1.8,7.13c-6.12,0-12,4.82-12,13.32s5.91,13.39,12,13.39,12.09-5,12.09-13.53S124.83,42.87,118.64,42.87Z"
        />
        <path
          className="ppt-text"
          d="M164.64,76.71c-11.37,0-19.8-8.06-19.8-20.52s8.07-20.45,19.8-20.45c11.31,0,19.3,7.85,19.3,19.51a25,25,0,0,1-.29,4H153.34c.58,6.55,5.26,10.51,11.3,10.51,5.05,0,7.85-2.45,9.37-5.55h8.85C180.63,71.17,174.37,76.71,164.64,76.71Zm-11.23-24h22c-.14-6.12-5-10-11.09-10C158.74,42.65,154.28,46.4,153.41,52.66Z"
        />
        <path className="ppt-text" d="M192.36,22.78h8.21V76.06h-8.21Z" />
        <path
          className="ppt-text"
          d="M210.65,25.88a5.22,5.22,0,1,1,5.26,5.25A5.19,5.19,0,0,1,210.65,25.88Zm1.08,10.51h8.21V76.06h-8.21Z"
        />
        <path
          className="ppt-text"
          d="M259.25,53.89c0-7.28-4-11-9.94-11s-10,3.74-10,11V76.06H231.1V36.39h8.21v4.53a15.24,15.24,0,0,1,11.8-5.18c9.29,0,16.28,5.83,16.28,16.92v23.4h-8.14Z"
        />
        <path
          className="ppt-text"
          d="M295.25,76.71c-11.38,0-19.8-8.06-19.8-20.52s8.06-20.45,19.8-20.45c11.3,0,19.3,7.85,19.3,19.51a25,25,0,0,1-.29,4H283.94c.58,6.55,5.26,10.51,11.31,10.51,5,0,7.85-2.45,9.36-5.55h8.86C311.23,71.17,305,76.71,295.25,76.71ZM284,52.66h22c-.15-6.12-5-10-11.09-10C289.35,42.65,284.88,46.4,284,52.66Z"
        />
      </g>
      <g>
        <path
          className="ppt-text"
          d="M336.41,75.83h23.86v28.42h27.8v19.3h-27.8v63.28c0,8.5,3.32,12,13.28,12A14.66,14.66,0,0,0,387.21,188l9.83,13.1c-5.46,15.29-22.93,15.29-22.93,15.29-20.34,0-37.7-6.35-37.7-29.59"
        />
        <path
          className="ppt-text"
          d="M460.53,218.26c-32.57,0-57.26-23.24-57.26-59.14S429,100.2,461.57,100.2s58.3,23,58.3,58.92S493.31,218.26,460.53,218.26Zm0-20.54c17.64,0,35.07-12.66,35.07-38.6s-16.81-38.38-34.45-38.38c-17.84,0-33.81,12.45-33.81,38.38S442.69,197.72,460.53,197.72Z"
        />
        <path
          className="ppt-text"
          d="M592.9,218.26c-32.57,0-57.27-23.24-57.27-59.14s25.73-58.92,58.31-58.92,58.3,23,58.3,58.92S625.68,218.26,592.9,218.26Zm0-20.54c17.63,0,35.06-12.66,35.06-38.6s-16.8-38.38-34.44-38.38c-17.84,0-33.82,12.45-33.82,38.38S575.06,197.72,592.9,197.72Z"
        />
        <path
          className="ppt-text"
          d="M775.22,218.26c-27.39,0-46.68-16.19-47.72-36.94H752c.83,9.34,9.76,17,22.83,17,13.69,0,21-5.81,21-13.7,0-22.4-66.39-9.54-66.39-51.45,0-18.26,17-33,44-33,25.93,0,42.94,13.9,44.19,36.72H793.9c-.83-10-8.51-16.8-21.38-16.8-12.65,0-19.29,5.18-19.29,12.86,0,23,64.53,10.17,65.77,51.46C819,203.73,802.19,218.26,775.22,218.26Z"
        />
      </g>
      <path
        className="ppt-text"
        d="M691,75.92V188.2c0,8.51,3.32,12,13.27,12a14.69,14.69,0,0,0,13.67-10.84l9.83,13.1c-5.46,15.29-22.93,15.29-22.93,15.29-20.34,0-37.7-6.35-37.7-29.59V75.92"
      />
      <circle className="cls-1" cx="897.56" cy="193.44" r="60" />
      <circle className="cls-2" cx="958" cy="108" r="90" />
      <g>
        <path
          className="ppt-text"
          d="M87,103.4c30.4,0,53,22.24,53,57.12s-22.64,57.53-53,57.53c-22.23,0-37.74-12.24-44.27-26.11v77.11H28.7V105H42.77v24.48C49.3,115.44,64.81,103.4,87,103.4Zm-2.85,12.45c-22.65,0-41.42,17.13-41.42,44.88s18.77,44.88,41.42,44.88c23.46,0,41.41-16.94,41.41-45.09C125.6,132.17,107.65,115.85,84.19,115.85Z"
        />
        <path
          className="ppt-text"
          d="M169.05,216.42H154.77V105h14.28v19.78C175.17,111.16,188,103,207.2,103v14.89h-3.88c-18.56,0-34.27,8.36-34.27,36.31Z"
        />
        <path
          className="ppt-text"
          d="M265.74,218.05c-31.41,0-55.28-22-55.28-57.32,0-35.5,24.48-57.33,55.69-57.33,31.42,0,55.69,21.83,55.69,57.33C321.84,196,297.16,218.05,265.74,218.05Zm0-12.44c21,0,41.41-14.28,41.41-44.88s-20-44.88-41.2-44.88-41,14.28-41,44.88S244.53,205.61,265.74,205.61Z"
        />
      </g>
    </svg>
  )
}